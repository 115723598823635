import React, { useState, useContext, useEffect } from "react";
import {
   BrowserRouter as Router,
   Routes,
   Route,
   useNavigate,
} from "react-router-dom";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";

import logo from "../images/evoke.svg";

import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import StopIcon from "@mui/icons-material/Stop";
import ResetIcon from "@mui/icons-material/Replay";
import UpArrow from "@mui/icons-material/KeyboardArrowUp";
import DownArrow from "@mui/icons-material/KeyboardArrowDown";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import TimeClock from "../components/TimeClock";
import TextInput from "../components/inputs/TextInput";
import ScaleToWidth from "../components/layout/ScaleToWidth.js";

//HOOKS
import useTimeclock from "../hooks/useTimeclock";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const FRONTEND_URL = process.env.REACT_APP_FRONTEND_URL;

export default function Admin() {
   const theme = useTheme();

   // USE HOOK
   const [saveRequired, active, update, { refresh }] = useTimeclock();

   const [isRunning, setIsRunning] = useState(false);
   const [hours, setHours] = useState(0);
   const [minutes, setMinutes] = useState(0);
   const [seconds, setSeconds] = useState(0);
   const [activeText, setActiveText] = useState();
   const [background, setBackground] = useState();

   useEffect(() => {
      if (active) {
         setHours(active.hours);
         setMinutes(active.minutes);
         setSeconds(active.seconds);
         setIsRunning(active.running);
         setBackground(active.bkgnd);
         setActiveText(active.text);
      }
   }, [active]);

   const confirmReset = () => {
      if (window.confirm("Are you sure you want to reset the time clock?")) {
         handleCounterControl(0);
      }
   };

   const confirmTimeCalled = () => {
      if (window.confirm("Are you sure you want to Clear Timer?")) {
         setActive("called");
         handleCounterControl(0);
      }
   };

   const addLeadingZero = (value) => {
      let updatedValue = value;

      if (value < 10) {
         updatedValue = "0" + value;
      }

      return updatedValue;
   };

   const setBkgnd = (value) => {
      update({ id: 1, bkgnd: value });
   };

   const setActive = (type) => {
      update({ id: 1, type: type });
   };

   const updateTimer = () => {
      update({
         id: 1,
         startTime: Date.now(),
         hours: hours,
         minutes: minutes,
         seconds: seconds,
         running: isRunning,
      });
   };

   const toggleMessageVisibility = (e) => {
      console.log(e.target.checked);
      update({
         id: 1,
         showMessage: e.target.checked,
      });
   };

   const resetHours = () => {
      setHours(0);
   };

   const resetMinutes = () => {
      setMinutes(0);
   };

   const resetSeconds = () => {
      setSeconds(0);
   };

   const handleAdjustHours = (value) => {
      setHours((prevState) => {
         let newState = prevState;
         return Math.max(newState + value, 0);
      });
   };

   const handleAdjustMinutes = (value) => {
      setMinutes((prevState) => {
         let newState = prevState;
         return Math.min(Math.max(newState + value, 0), 59);
      });
   };

   const handleAdjustSeconds = (value) => {
      setSeconds((prevState) => {
         let newState = prevState;
         return Math.min(Math.max(newState + value, 0), 55);
      });
   };

   const handleAddTime = (e) => {
      const value = parseInt(e.target.value);

      update({
         id: 1,
         startTime: active.startTime + value,
         running: true,
      });
   };

   const handleSetTime = (hours, minutes, seconds) => {
      setHours(hours);
      setMinutes(minutes);
      setSeconds(seconds);
   };

   const handleCounterControl = (value) => {
      if (value == 1) {
         update({
            id: 1,
            type: "timer",
            startTime: Date.now(),
            running: value,
         });
      } else {
         update({ id: 1, startTime: Date.now(), running: 0 });
      }
   };

   const handleTextUpdate = (value) => {
      update({ id: 1, text: value });
   };

   return (
      <Stack
         spacing={4}
         alignItems='center'
         sx={{
            height: "100vh",
            width: "100vw",
            overflowX: "hidden",
            overflowY: "auto",
            pb: 8,
         }}
      >
         <Stack
            sx={{
               py: 2,
               width: "100%",
               color: "#fff",
               backgroundColor: "#4f0202",
            }}
            alignItems='center'
            justifyContent='center'
            spacing={1}
            textAlign='center'
         >
            <Typography variant='h5' component='div'>
               TIMECLOCK CONTROLLER
            </Typography>

            <TextField
               value={SERVER_URL + "/viewer"}
               sx={{ width: 350 }}
               size='small'
               variant='filled'
               label='VIEWER URL'
               InputProps={{
                  sx: {
                     borderRadius: 0,
                     color: "#fff",
                     backgroundColor: "rgba(255,255,255,.4)",
                  },
               }}
            />
         </Stack>

         <Stack
            px={10}
            spacing={2}
            sx={{ maxWidth: 1000, minWidth: 800, width: "100%" }}
         >
            <Stack
               direction='row'
               alignItems='center'
               justifyContent='center'
               spacing={2}
            >
               <Button
                  onClick={() => setActive("slide")}
                  variant={
                     active && active.type == "slide" ? "contained" : "outlined"
                  }
                  sx={{ minWidth: 200 }}
               >
                  SHOW MESSAGE
               </Button>

               <TextInput
                  callback={handleTextUpdate}
                  value={activeText}
                  color='#fff'
                  InputProps={{ sx: { borderRadius: 0, color: "#fff" } }}
               />
            </Stack>

            <Typography
               variant='h5'
               component='div'
               sx={{ pt: 4, color: "#fff" }}
            >
               PREVIEW
            </Typography>

            <ScaleToWidth contentWidth={1920} contentHeight={1080}>
               {(parent) => (
                  <iframe
                     src='/viewer'
                     style={{ width: 1920, height: 1080 }}
                  ></iframe>
               )}
            </ScaleToWidth>

            <Stack
               direction='row'
               spacing={2}
               justifyContent='space-between'
               alignItems='center'
            >
               <Stack direction='row' spacing={2} alignItems='center'>
                  <Button
                     onClick={() => setActive("timer")}
                     variant={
                        active && active.type == "timer"
                           ? "contained"
                           : "outlined"
                     }
                     color={
                        active && active.type == "timer" ? "error" : "secondary"
                     }
                  >
                     TIMER
                  </Button>

                  <FormGroup
                     sx={{
                        backgroundColor: "rgba(255,255,255,.1)",
                        borderRadius: 1,
                        px: 2,
                     }}
                  >
                     <FormControlLabel
                        sx={{ color: "#aaa" }}
                        control={
                           <Switch
                              checked={
                                 active && active.showMessage
                                    ? active.showMessage
                                    : false
                              }
                              onChange={toggleMessageVisibility}
                           />
                        }
                        label='Show Message with Clock'
                     />
                  </FormGroup>

                  <Button
                     onClick={confirmTimeCalled}
                     variant={
                        active && active.type == "called"
                           ? "contained"
                           : "outlined"
                     }
                     color={
                        active && active.type == "called"
                           ? "error"
                           : "secondary"
                     }
                  >
                     CLEAR TIMER
                  </Button>
               </Stack>

               <Button
                  onClick={() => setActive("timeofday")}
                  variant={
                     active && active.type == "timeofday"
                        ? "contained"
                        : "outlined"
                  }
                  color={
                     active && active.type == "timeofday"
                        ? "error"
                        : "secondary"
                  }
               >
                  TIME OF DAY
               </Button>
            </Stack>

            <Stack
               spacing={2}
               py={4}
               alignItems='center'
               justifyContent='center'
            >
               {active && (
                  <>
                     <Stack
                        direction='row'
                        alignItems='flex-start'
                        justifyContent='center'
                        spacing={2}
                        pt={3}
                        pb={1}
                        sx={{
                           position: "relative",
                           width: "100%",
                           backgroundColor:
                              active.hours != hours ||
                              active.minutes != minutes ||
                              active.seconds != seconds
                                 ? "rgba(255,50,50,.4)"
                                 : "rgba(255,255,255,.2)",
                        }}
                     >
                        {isRunning && (
                           <Stack
                              justifyContent='center'
                              sx={{
                                 position: "absolute",
                                 zIndex: 100,
                                 top: 0,
                                 left: 0,
                                 height: "100%",
                                 width: "100%",
                                 backgroundColor: "rgba(0,0,0,.8)",
                              }}
                           >
                              <Typography
                                 variant='h6'
                                 component='div'
                                 sx={{
                                    fontSize: "1em",
                                    color: "#f00",
                                    textAlign: "center",
                                    fontWeight: "bold",
                                 }}
                              >
                                 CLOCK IS RUNNING — RESET TO EDIT
                                 <Button
                                    variant='contained'
                                    color='error'
                                    onClick={confirmReset}
                                    sx={{ ml: 2 }}
                                 >
                                    <ResetIcon />
                                 </Button>
                              </Typography>
                           </Stack>
                        )}

                        <Stack
                           direction='row'
                           alignItems='flex-start'
                           spacing={2}
                        >
                           <Stack>
                              <h6>Hours</h6>
                              <h3>{addLeadingZero(hours)}</h3>
                              <Button size='small' onClick={resetHours}>
                                 CLEAR
                              </Button>
                           </Stack>
                           <Stack spacing={1}>
                              <Button
                                 onClick={() => handleAdjustHours(1)}
                                 variant='contained'
                              >
                                 <UpArrow />
                              </Button>
                              <Button
                                 onClick={() => handleAdjustHours(-1)}
                                 variant='contained'
                              >
                                 <DownArrow />
                              </Button>
                           </Stack>
                        </Stack>

                        <Stack
                           direction='row'
                           alignItems='flex-start'
                           spacing={2}
                        >
                           <Stack>
                              <h6>Minutes</h6>
                              <h3>{addLeadingZero(minutes)}</h3>
                              <Button size='small' onClick={resetMinutes}>
                                 CLEAR
                              </Button>
                           </Stack>
                           <Stack spacing={1}>
                              <Button
                                 onClick={() => handleAdjustMinutes(1)}
                                 variant='contained'
                              >
                                 <UpArrow />
                              </Button>
                              <Button
                                 onClick={() => handleAdjustMinutes(-1)}
                                 variant='contained'
                              >
                                 <DownArrow />
                              </Button>
                           </Stack>
                        </Stack>

                        <Stack
                           direction='row'
                           alignItems='flex-start'
                           spacing={2}
                        >
                           <Stack>
                              <h6>Seconds</h6>
                              <h3>{addLeadingZero(seconds)}</h3>
                              <Button size='small' onClick={resetSeconds}>
                                 CLEAR
                              </Button>
                           </Stack>
                           <Stack spacing={1}>
                              <Button
                                 onClick={() => handleAdjustSeconds(5)}
                                 variant='contained'
                              >
                                 <UpArrow />
                              </Button>
                              <Button
                                 onClick={() => handleAdjustSeconds(-5)}
                                 variant='contained'
                              >
                                 <DownArrow />
                              </Button>
                           </Stack>
                        </Stack>
                     </Stack>

                     {active &&
                        (active.hours != hours ||
                           active.minutes != minutes ||
                           active.seconds != seconds) && (
                           <Button
                              onClick={updateTimer}
                              variant='contained'
                              color='error'
                              sx={{ px: 4 }}
                           >
                              Set Time Clock
                           </Button>
                        )}

                     <Stack
                        direction='row'
                        width='100%'
                        spacing={1}
                        sx={{ backgroundColor: "#222", p: 2 }}
                     >
                        <Grid container sx={{ width: "100%" }} spacing={1}>
                           <Grid item xs={12}>
                              <h6>PRESETS</h6>
                           </Grid>
                           <Grid item xs={6}>
                              <Stack spacing={1}>
                                 <Button
                                    onClick={() => handleSetTime(0, 1, 0)}
                                    variant='outlined'
                                    size='small'
                                    disabled={isRunning ? true : false}
                                 >
                                    00:01:00
                                 </Button>
                                 <Button
                                    onClick={() => handleSetTime(0, 5, 0)}
                                    variant='outlined'
                                    size='small'
                                    disabled={isRunning ? true : false}
                                 >
                                    00:05:00
                                 </Button>
                                 <Button
                                    onClick={() => handleSetTime(0, 10, 0)}
                                    variant='outlined'
                                    size='small'
                                    disabled={isRunning ? true : false}
                                 >
                                    00:10:00
                                 </Button>
                              </Stack>
                           </Grid>
                           <Grid item xs={6}>
                              <Stack spacing={1}>
                                 <Button
                                    onClick={() => handleSetTime(0, 15, 0)}
                                    variant='outlined'
                                    size='small'
                                    disabled={isRunning ? true : false}
                                 >
                                    00:15:00
                                 </Button>
                                 <Button
                                    onClick={() => handleSetTime(0, 30, 0)}
                                    variant='outlined'
                                    size='small'
                                    disabled={isRunning ? true : false}
                                 >
                                    00:30:00
                                 </Button>
                                 <Button
                                    onClick={() => handleSetTime(0, 50, 0)}
                                    variant='outlined'
                                    size='small'
                                    disabled={isRunning ? true : false}
                                 >
                                    00:50:00
                                 </Button>
                              </Stack>
                           </Grid>
                        </Grid>

                        <Grid container sx={{ width: "100%" }} spacing={1}>
                           <Grid item xs={12}>
                              <h6>JOG</h6>
                           </Grid>
                           <Grid item xs={6}>
                              <Stack spacing={1}>
                                 <Button
                                    onClick={handleAddTime}
                                    value={60000}
                                    variant='outlined'
                                    size='small'
                                    disabled={!isRunning ? true : false}
                                 >
                                    +1 min
                                 </Button>

                                 <Button
                                    onClick={handleAddTime}
                                    value={60000 * 5}
                                    variant='outlined'
                                    size='small'
                                    disabled={!isRunning ? true : false}
                                 >
                                    +5 min
                                 </Button>

                                 <Button
                                    onClick={handleAddTime}
                                    value={60000 * 10}
                                    variant='outlined'
                                    size='small'
                                    disabled={!isRunning ? true : false}
                                 >
                                    +10 min
                                 </Button>
                              </Stack>
                           </Grid>
                           <Grid item xs={6}>
                              <Stack spacing={1}>
                                 <Button
                                    onClick={handleAddTime}
                                    value={-60000}
                                    variant='outlined'
                                    size='small'
                                    disabled={!isRunning ? true : false}
                                 >
                                    -1 min
                                 </Button>
                                 <Button
                                    onClick={handleAddTime}
                                    value={-60000 * 5}
                                    variant='outlined'
                                    size='small'
                                    disabled={!isRunning ? true : false}
                                 >
                                    -5 min
                                 </Button>
                                 <Button
                                    onClick={handleAddTime}
                                    value={-60000 * 10}
                                    variant='outlined'
                                    size='small'
                                    disabled={!isRunning ? true : false}
                                 >
                                    -10 min
                                 </Button>
                              </Stack>
                           </Grid>
                        </Grid>

                        <Button
                           onClick={() => handleCounterControl(1)}
                           color='success'
                           variant='contained'
                           disabled={isRunning ? true : false}
                           sx={{ px: 4 }}
                        >
                           <PlayArrowIcon sx={{ fontSize: 60 }} />
                        </Button>
                     </Stack>
                  </>
               )}
            </Stack>
         </Stack>

         <Typography variant='h5' component='div' sx={{ pt: 4, color: "#fff" }}>
            BACKGROUND
         </Typography>
         <Stack
            direction='row'
            spacing={1}
            justifyContent='center'
            sx={{ pb: 5 }}
         >
            <Button
               onClick={() => setBkgnd("black")}
               variant={background == "black" ? "contained" : "outlined"}
               color='secondary'
            >
               BLACK
            </Button>
            <Button
               onClick={() => setBkgnd("blue")}
               variant={background == "blue" ? "contained" : "outlined"}
               color='secondary'
            >
               BLUE
            </Button>
            <Button
               onClick={() => setBkgnd("green")}
               variant={background == "green" ? "contained" : "outlined"}
               color='secondary'
            >
               GREEN
            </Button>
            <Button
               onClick={() => setBkgnd("red")}
               variant={background == "red" ? "contained" : "outlined"}
               color='secondary'
            >
               RED
            </Button>
            <Button
               onClick={() => setBkgnd("smoke")}
               variant={background == "smoke" ? "contained" : "outlined"}
               color='secondary'
            >
               SMOKE
            </Button>
            <Button
               onClick={() => setBkgnd("white")}
               variant={background == "white" ? "contained" : "outlined"}
               color='secondary'
            >
               WHITE
            </Button>
         </Stack>
      </Stack>
   );
}
