import React, { useState, useContext, useEffect } from "react";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import Countdown from "react-countdown";
import Counter from "../components/Counter";

export default function TimeCalled() {
   return (
      <Stack w='100%' h='100%' alignItems='center' justifyContent='center'>
         <Typography variant='h1' component='h1' sx={{ color: "#f00" }}>
            {/* ROUND ENDED */}
         </Typography>
      </Stack>
   );
}
