import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import Countdown from "react-countdown";

import { motion, AnimatePresence } from "framer-motion";
import { Button, Stack } from "@mui/material";

const addLeadingZero = (value) => {
   let updatedValue = value;

   if (value < 10) {
      updatedValue = "0" + value;
   }

   return updatedValue;
};

export default function Counter(
   { api, days, hours, minutes, seconds, total, completed },
   props
) {
   switch (props.control) {
      case "start":
         api.start();
         break;
      case "stop":
         api.stop();
         break;
      case "pause":
         api.pause();
         break;
   }

   return (
      <AnimatePresence mode='wait'>
         <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, duration: 2 }}
            exit={{ opacity: 0, duration: 0.5 }}
         >
            {total <= 0 && <h1>00:00</h1>}
            {total > 0 && (
               <h1>
                  {hours > 0 && <>{addLeadingZero(hours)}:</>}
                  {addLeadingZero(minutes)}:{addLeadingZero(seconds)}
               </h1>
            )}
         </motion.div>
      </AnimatePresence>
   );
}
