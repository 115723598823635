import React, { useState, useContext, useEffect } from "react";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import Countdown from "react-countdown";
import Counter from "../components/Counter";

import Balancer from "react-wrap-balancer";

export default function TimeOfDayClock() {
   const [currentTime, setCurrentTime] = useState("");

   useEffect(() => {
      let currentTime = new Date();
      setCurrentTime(
         String(currentTime.getHours()) +
            ":" +
            String(currentTime.getMinutes()).padStart(2, "0") +
            ":" +
            String(currentTime.getSeconds()).padStart(2, "0")
      );

      setTimeout(function () {
         const timerId = setInterval(() => {
            currentTime = new Date();
            setCurrentTime(
               String(currentTime.getHours()) +
                  ":" +
                  String(currentTime.getMinutes()).padStart(2, "0") +
                  ":" +
                  String(currentTime.getSeconds()).padStart(2, "0")
            );
         }, 300);
         return function cleanup() {
            clearInterval(timerId);
         };
      }, 300);
   }, []);

   return (
      <Stack
         w='100%'
         h='100%'
         alignItems='center'
         justifyContent='center'
         spacing={1}
      >
         <Box key='timeofday' sx={{ fontSize: "2em" }}>
            <h1>{currentTime}</h1>
         </Box>

         <Stack sx={{ fontSize: ".9em", px: 10 }}>
            <Typography
               variant='h2'
               component='h2'
               sx={{ color: "#fff", textAlign: "center" }}
            >
               <Balancer>Time of Day</Balancer>
            </Typography>
         </Stack>
      </Stack>
   );
}
