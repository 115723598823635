import React, { useState, useRef, useContext, useEffect } from "react";

import { Button, Box, Stack, Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

// COMPONENTS
import TextSlide from "../components/TextSlide";
import TimeClock from "../components/TimeClock";
import TimeOfDayClock from "../components/TimeOfDayClock";
import TimeCalled from "../components/TimeCalled";
import WrapperViewer from "../components/layout/WrapperViewer";

import { motion, AnimatePresence } from "framer-motion";

import logo from "../assets/logo-115.png";
import bkgndBlack from "../assets/bkgnd-black.png";
import bkgndBlue from "../assets/bkgnd-blue.png";
import bkgndGreen from "../assets/bkgnd-green.png";
import bkgndRed from "../assets/bkgnd-red.png";
import bkgndSmoke from "../assets/bkgnd-smoke.png";
import bkgndWhite from "../assets/bkgnd-white.png";

//HOOKS
import useTimeclock from "../hooks/useTimeclock";

// CONST
const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const backgrounds = {
   black: bkgndBlack,
   blue: bkgndBlue,
   green: bkgndGreen,
   red: bkgndRed,
   smoke: bkgndSmoke,
   white: bkgndWhite,
};

const ActiveSlide = ({ data }) => {
   return (
      <>
         {(() => {
            switch (data.type) {
               case "slide":
                  return <TextSlide data={data} />;
               case "timer":
                  return <TimeClock data={data} />;
               case "timeofday":
                  return <TimeOfDayClock data={data} />;
               case "called":
                  return <TimeCalled />;
               default:
                  return null;
            }
         })()}
      </>
   );
};

export default function TimerBackground() {
   const theme = useTheme();

   // USE HOOK
   const [saveRequired, active, update, { refresh }] = useTimeclock();

   return (
      <Box
         sx={{
            width: 1920,
            height: 1080,
            position: "relative",
            overflow: "hidden",
         }}
      >
         <Box
            sx={{
               position: "absolute",
               top: 40,
               right: 100,
               height: 200,
            }}
         >
            <img src={SERVER_URL + "/images/logo-800.png"} width={125} />
         </Box>
         <Box key={active && active.minutes}>
            {active && (
               <img src={backgrounds[active.bkgnd]} style={{ width: "100%" }} />
            )}

            {active && (
               <>
                  <Stack
                     sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        zIndex: 10,
                        color: "#fff",
                     }}
                     alignItems='center'
                     justifyContent='center'
                  >
                     <ActiveSlide data={active} />
                  </Stack>
               </>
            )}
         </Box>
      </Box>
   );
}
