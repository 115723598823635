import React, { useState, useContext, useEffect } from "react";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import Countdown from "react-countdown";
import Counter from "../components/Counter";

import Balancer from "react-wrap-balancer";

export default function TimeClock({ data }) {
   return (
      <Stack
         w='100%'
         h='100%'
         alignItems='center'
         justifyContent='center'
         spacing={1}
      >
         <Box key={data} sx={{ fontSize: "2em" }}>
            <Countdown
               date={
                  data.running
                     ? new Date(
                          data.startTime +
                             data.hours * 1000 * 60 * 60 +
                             data.minutes * 1000 * 60 +
                             data.seconds * 1000
                       )
                     : Date.now() +
                       data.hours * 1000 * 60 * 60 +
                       data.minutes * 1000 * 60 +
                       data.seconds * 1000
               }
               intervalDelay={250}
               renderer={(props) =>
                  Counter(props, {
                     control: data.running ? "start" : "stop",
                  })
               }
               overtime={true}
               autoStart={false}
               // onStart={() => setIsRunning(true)}
               // onPause={() => setIsRunning(false)}
            />
         </Box>

         {data.showMessage && (
            <Stack sx={{ fontSize: ".9em", px: 10 }}>
               <Typography
                  variant='h2'
                  component='h2'
                  sx={{ color: "#fff", textAlign: "center" }}
               >
                  <Balancer>{data.text}</Balancer>
               </Typography>
            </Stack>
         )}
      </Stack>
   );
}
