import { useState, useMemo, useContext, useEffect, useRef } from "react";
import { SocketContext } from "../context/socket";

import axios from "axios";

function useTimeclock() {
   const socket = useContext(SocketContext);

   const [saveRequired, setSaveRequired] = useState(false);
   const [active, setActive] = useState();

   useEffect(() => {
      handlers.subscribe();
      handlers.getData();
   }, []);

   useEffect(() => {
      // console.log(active);
   }, [active]);

   const update = (data) => {
      socket.emit("update", data);
   };

   const handlers = useMemo(
      () => ({
         refresh: (tableId) => {
            socket.emit("refreshTimeclock");
         },
         getData: () => {
            socket.emit("getData", (response) => {
               console.log(response);
            });
         },
         subscribe: () => {
            socket.emit("subscribeTimeclockViewer", (response) => {
               console.log(response);
            });

            socket.on("setActive", (data) => {
               setActive(data);
            });

            socket.on("refreshTimeclock", (data) => {
               console.log(data);
            });
         },
      }),
      [socket]
   );

   return [saveRequired, active, update, handlers];
}

export default useTimeclock;
