import React, { useState, useContext, useEffect } from "react";

import { motion, AnimatePresence } from "framer-motion";
import _ from "lodash";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import Balancer from "react-wrap-balancer";

export default function TextSlide({ data }) {
   return (
      <Stack
         w='100%'
         h='100%'
         alignItems='center'
         justifyContent='center'
         sx={{ px: 20, textAlign: "center" }}
      >
         <Box sx={{ fontSize: "1.7em" }}>
            <Typography variant='h2' component='h2' sx={{ color: "#fff" }}>
               <Balancer>{data.text}</Balancer>
            </Typography>
         </Box>
      </Stack>
   );
}
